import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Grid, CardContent, Card, Link } from '@mui/material';

const UserStats = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('/admin/stats/user-stats', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setStats(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user stats:', error);
        setError('Error fetching user stats');
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5">Users</Typography>
              <Typography variant="body1">Total Users: {stats.totalUsers}</Typography>
              <Typography variant="body1">Blocked Users: {stats.blockedUsers}</Typography>
              <Typography variant="body1">Referrer Level 1 Count: {stats.referrer1Count}</Typography>
              <Typography variant="body1">Referrer Level 2 Count: {stats.referrer2Count}</Typography>
              <Typography variant="body1">Referrer Level 3 Count: {stats.referrer3Count}</Typography>
            </CardContent>
            <CardContent>
              <Typography variant="h5">Withdrawals</Typography>
              <Typography variant="body1">Pending Withdrawals: {stats.pendingWithdrawals}</Typography>
              <Typography variant="body1">Total Withdrawn FERMA: {stats.totalWithdrawnFerma}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5">Balances</Typography>
              <Typography variant="body1">Total FSD: {stats.totalFsd}</Typography>
              <Typography variant="body1">Total FERMA: {stats.totalFerma}</Typography>
              <Typography variant="body1">Total Burned FSD: {stats.totalBurnedFsd}</Typography>
              <Typography variant="body1">Total Burned FERMA: {stats.totalBurnedFerma}</Typography>
              <Typography variant="body1">Total Real Burned FERMA: {stats.totalRealBurnedFerma}</Typography>
              <Typography variant="body1">Total Deposited FERMA: {stats.totalDepositedFerma}</Typography>
              <Typography variant="body1">Total Referral FERMA: {stats.totalReferralFerma}</Typography>
              <Typography variant="body1">Boost X2 Active: {stats.boostX2Users}</Typography>
              <Typography variant="body1">Boost Timer Purchased: {stats.boostTimer}</Typography>
              <Typography variant="body1">Pending Tasks: {stats.pendingTasks}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Add Followers and Retweets Stats */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5">Twitter Stats</Typography>
              <Typography variant="body1">Total Followers: {stats.totalTwitterFollowers}</Typography>
              {stats.retweetStats && stats.retweetStats.map((retweet) => (
                <Typography key={retweet.tweetId} variant="body1">
                  Retweets for <Link href={`https://x.com/FermaSosediBiz/status/${retweet.tweetId}/retweets`} target="_blank">Tweet ID {retweet.tweetId}</Link>: {retweet.count}
                </Typography>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserStats;
