import React from 'react';
import UserList from '../components/UserList';
import UserStats from '../components/UserStats';

const Home = () => {
  return (
    <div>
      <UserStats />
      <UserList />
    </div>
  );
};

export default Home;
