import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

const UserList = () => {
  const [users, setUsers] = useState([]);
  //const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('username');
  const [searchQuery, setSearchQuery] = useState('');

  const truncateName = (firstName, lastName) => {
    const fullName = `${firstName} ${lastName}`;
    return fullName.length > 15 ? `${fullName.slice(0, 15)}...` : fullName;
  };

  const fetchUsers = async (query, sortField, sortOrder) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/admin/users?sortField=${sortField}&sortOrder=${sortOrder}&searchQuery=${query}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Fetched users:', response.data); // Log the fetched users
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user list:', error);
      setError('Error fetching user list');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(searchQuery, orderBy, order);
  }, [order, orderBy, searchQuery]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Typography variant="h4" gutterBottom>
          User List
        </Typography>
        <TextField
          label="Search Users"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 3 }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection={orderBy === 'telegramId' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'telegramId'}
                    direction={orderBy === 'telegramId' ? order : 'asc'}
                    onClick={() => handleSort('telegramId')}
                  >
                    TelegramId
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'firstName' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'firstName'}
                    direction={orderBy === 'firstName' ? order : 'asc'}
                    onClick={() => handleSort('firstName')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'username' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'username'}
                    direction={orderBy === 'username' ? order : 'asc'}
                    onClick={() => handleSort('username')}
                  >
                    Username
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'referrer1' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'referrer1'}
                    direction={orderBy === 'referrer1' ? order : 'asc'}
                    onClick={() => handleSort('referrer1')}
                  >
                    Referrer 1
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'referrer2' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'referrer2'}
                    direction={orderBy === 'referrer2' ? order : 'asc'}
                    onClick={() => handleSort('referrer2')}
                  >
                    Referrer 2
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'referrer3' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'referrer3'}
                    direction={orderBy === 'referrer3' ? order : 'asc'}
                    onClick={() => handleSort('referrer3')}
                  >
                    Referrer 3
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(users) && users.length > 0 ? users.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.telegramId}</TableCell>
                  <TableCell>{truncateName(user.firstName, user.lastName)}</TableCell>
                  <TableCell>@{user.username}</TableCell>
                  <TableCell>{user.referrer1}</TableCell>
                  <TableCell>{user.referrer2}</TableCell>
                  <TableCell>{user.referrer3}</TableCell>
                  <TableCell>
                    <Link to={`/user/${user._id}`}>View Details</Link>
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">No users found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default UserList;
