import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';

const DailyCombo = () => {
  const [dailyCombos, setDailyCombos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/daily-combo', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDailyCombos(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Typography variant="h4" gutterBottom>
          Daily Combos
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Combo</TableCell>
                <TableCell>Generated At</TableCell>
                <TableCell>Next Combo Time</TableCell>
                <TableCell>Completions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dailyCombos.map(combo => (
                <TableRow key={combo._id}>
                  <TableCell>
                    <Link to={`/daily-combo/${combo._id}`}>{combo.combo.join(', ')}</Link>
                  </TableCell>
                  <TableCell>{new Date(combo.generatedAt).toLocaleString()}</TableCell>
                  <TableCell>{new Date(combo.nextComboTime).toLocaleString()}</TableCell>
                  <TableCell>{combo.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default DailyCombo;
