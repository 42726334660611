import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ComboDetails = () => {
  const { id } = useParams();
  const [comboDetails, setComboDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchComboDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/admin/daily-combo/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setComboDetails(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching combo details');
        setLoading(false);
      }
    };

    fetchComboDetails();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const { comboDetails: details, userResponses } = comboDetails;

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Typography variant="h4" gutterBottom>
          Combo Details
        </Typography>
        <Typography variant="body1">
          Combo: {details.combo.join(', ')}
        </Typography>
        <Typography variant="body1">
          Generated At: {new Date(details.generatedAt).toLocaleString()}
        </Typography>
        <Typography variant="body1">
          Next Combo Time: {new Date(details.nextComboTime).toLocaleString()}
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          User Responses
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Is Correct</TableCell>
                <TableCell>Reward</TableCell>
                <TableCell>Submitted At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userResponses.map(response => (
                <TableRow key={response._id}>
                  <TableCell>{`${response.userId.firstName} ${response.userId.lastName}`.substring(0, 30)} ({response.userId.telegramId})</TableCell>
                  <TableCell>{response.isCorrect ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{response.reward}</TableCell>
                  <TableCell>{new Date(response.createdAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default ComboDetails;
