import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Container, Typography, CircularProgress, Box, Paper, Button,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  MenuItem, Select, FormControl, InputLabel, Link
} from '@mui/material';

const UserDetails = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [boostStatus, setBoostStatus] = useState('');
  const [boostStatusError, setBoostStatusError] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/admin/users/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUserDetails(response.data);
        setBoostStatus(response.data.userStats.boostStatus || '');
        setLoading(false);
      } catch (error) {
        setError('Error fetching user details');
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleDeleteCompletedTask = async (taskId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`/admin/verification-tasks/delete/${taskId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserDetails(prevDetails => ({
        ...prevDetails,
        completedTasks: prevDetails.completedTasks.filter(task => task._id !== taskId)
      }));
    } catch (error) {
      setError('Error deleting completed task');
    }
  };

  const handleBoostStatusChange = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`/admin/users/${id}/user-stats`, { boostStatus }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserDetails(prevDetails => ({
        ...prevDetails,
        userStats: response.data
      }));
      setBoostStatusError('');
    } catch (error) {
      setError('Error updating boost status');
      if (error.response && error.response.data && error.response.data.errors) {
        setBoostStatusError(error.response.data.errors[0].msg);
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const { user, userStats, referralEarnings, withdrawals, deposits, completedTasks, userBscAddress, referrer1Count, referrer2Count, referrer3Count, marketplaceHistory } = userDetails;

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            User Details
          </Typography>
          <Typography variant="body1">
            Name: {user.firstName} {user.lastName}
          </Typography>
          <Typography variant="body1">
            Username: {user.username}
          </Typography>
          <Typography variant="body1">
            Telegram ID: {user.telegramId}
          </Typography>
          <Typography variant="body1">
            Premium: {user.premium ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="body1">
            Language: {user.language}
          </Typography>
          <Typography variant="body1">
            IP: {user.ip}
          </Typography>
          <Typography variant="body1">
            Country: {user.country}
          </Typography>
          <Typography variant="body1">
            Registered At: {new Date(user.registeredAt).toLocaleString()}
          </Typography>
          <Typography variant="body1">
            Last Accessed At: {new Date(user.lastAccessedAt).toLocaleString()}
          </Typography>
          <Typography variant="body1">
            Show Welcome Message: {user.showWelcomeMessage ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="body1">
            User Type: {user.userType}
          </Typography>
          <Typography variant="body1">
            Referrals Level 1: {user.referralsLevel1}
          </Typography>
          <Typography variant="body1">
            Referrals Level 2: {user.referralsLevel2}
          </Typography>
          <Typography variant="body1">
            Referrals Level 3: {user.referralsLevel3}
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Referral Counts
          </Typography>
          <Typography variant="body1">
            Level 1 Referrals: {referrer1Count}
          </Typography>
          <Typography variant="body1">
            Level 2 Referrals: {referrer2Count}
          </Typography>
          <Typography variant="body1">
            Level 3 Referrals: {referrer3Count}
          </Typography>
        </Box>

        {userStats && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              User Stats
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Level</TableCell>
                    <TableCell>{userStats.level}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Boost Status</TableCell>
                    <TableCell>
                      <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel>Boost Status</InputLabel>
                        <Select
                          value={boostStatus}
                          onChange={(e) => setBoostStatus(e.target.value)}
                          label="Boost Status"
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                        {boostStatusError && <Typography color="error">{boostStatusError}</Typography>}
                      </FormControl>
                      <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleBoostStatusChange}>
                        Update Boost Status
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Referral Boost Status</TableCell>
                    <TableCell>{userStats.boostStatus}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Claim</TableCell>
                    <TableCell>{userStats.lastClaim ? new Date(userStats.lastClaim).toLocaleString() : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>FSD Balance</TableCell>
                    <TableCell>{userStats.fsdBalance}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>FERMA Balance</TableCell>
                    <TableCell>{userStats.fermaBalance}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>FSD Burned</TableCell>
                    <TableCell>{userStats.burnedFsdBalance}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>FERMA Burned</TableCell>
                    <TableCell>{userStats.burnedFermaBalance}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Harvest Notification Sent</TableCell>
                    <TableCell>{userStats.harvestNotificationSent ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Boost Active</TableCell>
                    <TableCell>{userStats.boostActive ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Boost Expiry</TableCell>
                    <TableCell>{userStats.boostExpiry ? new Date(userStats.boostExpiry).toLocaleString() : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ads Viewed For Boost</TableCell>
                    <TableCell>{userStats.adsViewedForBoost}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Timer Boost Purchased</TableCell>
                    <TableCell>{userStats.timerBoostPurchased ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Timer Boost Active</TableCell>
                    <TableCell>{userStats.timerBoostActive ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Timer Boost Expiry</TableCell>
                    <TableCell>{userStats.timerBoostExpiry ? new Date(userStats.timerBoostExpiry).toLocaleString() : 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {referralEarnings && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Referral Earnings
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Level</TableCell>
                    <TableCell>FSD</TableCell>
                    <TableCell>FERMA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Level 1</TableCell>
                    <TableCell>{referralEarnings.fsd.level1}</TableCell>
                    <TableCell>{referralEarnings.ferma.level1}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Level 2</TableCell>
                    <TableCell>{referralEarnings.fsd.level2}</TableCell>
                    <TableCell>{referralEarnings.ferma.level2}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Level 3</TableCell>
                    <TableCell>{referralEarnings.fsd.level3}</TableCell>
                    <TableCell>{referralEarnings.ferma.level3}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {userBscAddress && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              BSC Address
            </Typography>
            <Typography variant="body1">
              Address: {userBscAddress.bscAddress}
            </Typography>
          </Box>
        )}

        {deposits.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Deposits
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Amount</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deposits.map(deposit => (
                    <TableRow key={deposit._id}>
                      <TableCell>{deposit.amount}</TableCell>
                      <TableCell>{new Date(deposit.date).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {withdrawals.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Withdrawals
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withdrawals.map(withdrawal => (
                    <TableRow key={withdrawal._id}>
                      <TableCell>{withdrawal.amount}</TableCell>
                      <TableCell>{withdrawal.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {marketplaceHistory && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Marketplace History
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Action</TableCell>
                    <TableCell>Referral ID</TableCell>
                    <TableCell>Seller ID</TableCell>
                    <TableCell>Buyer ID</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {marketplaceHistory.map(history => (
                    <TableRow key={history._id}>
                      <TableCell>{history.action}</TableCell>
                      <TableCell>
                        <Link component={RouterLink} to={`/user/${history.referralId}`}>
                          {history.referralId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link component={RouterLink} to={`/user/${history.sellerId}`}>
                          {history.sellerId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link component={RouterLink} to={`/user/${history.buyerId}`}>
                          {history.buyerId}
                        </Link>
                      </TableCell>
                      <TableCell>{history.price}</TableCell>
                      <TableCell>{new Date(history.date).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {completedTasks.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Completed Tasks
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Task ID</TableCell>
                    <TableCell>Reward</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>rejectionCount</TableCell>
                    <TableCell>moderatorMessage</TableCell>
                    <TableCell>Completed At</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completedTasks.map(task => (
                    <TableRow key={task._id}>
                      <TableCell>{task.taskId}</TableCell>
                      <TableCell>{task.reward}</TableCell>
                      <TableCell>{task.link}</TableCell>
                      <TableCell>{task.verificationStatus}</TableCell>
                      <TableCell>{task.rejectionCount}</TableCell>
                      <TableCell>{task.moderatorMessage}</TableCell>
                      <TableCell>{new Date(task.completedAt).toLocaleString()}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="secondary" onClick={() => handleDeleteCompletedTask(task._id)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default UserDetails;
