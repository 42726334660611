import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Paper, Button, Box, Select, MenuItem } from '@mui/material';

const VerificationTasks = () => {
  const [pendingTasks, setPendingTasks] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [moderatorMessages, setModeratorMessages] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [taskType, setTaskType] = useState('all'); // Adăugarea selecției pentru tipul de task
  const tasksPerPage = 50;

  const fetchPendingTasks = async (page, type) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/admin/verification-tasks/pending', {
        headers: { Authorization: `Bearer ${token}` },
        params: { limit: tasksPerPage, skip: (page - 1) * tasksPerPage, type: type !== 'all' ? type : undefined }
      });

      if (!response.data || !Array.isArray(response.data.pendingTasks)) {
        throw new Error('Unexpected response format');
      }

      setPendingTasks(response.data.pendingTasks);
      setTotalTasks(response.data.totalTasks);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pending tasks:', error);
      setError('Error fetching pending tasks');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingTasks(currentPage, taskType);
  }, [currentPage, taskType]);

  const handleApprove = async (taskId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/admin/verification-tasks/approve/${taskId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPendingTasks(pendingTasks.filter(task => task._id !== taskId));
    } catch (error) {
      setError('Error approving task');
    }
  };

  const handleDisapprove = async (taskId) => {
    try {
      const token = localStorage.getItem('token');
      const message = moderatorMessages[taskId] || '';
      await axios.post(`/admin/verification-tasks/disapprove/${taskId}`, { moderatorMessage: message }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPendingTasks(pendingTasks.filter(task => task._id !== taskId));
    } catch (error) {
      setError('Error disapproving task');
    }
  };

  const handleModeratorMessageChange = (taskId, message) => {
    setModeratorMessages(prevState => ({
      ...prevState,
      [taskId]: message
    }));
  };

  const handleAutoCheck = async () => {
    try {
      const token = localStorage.getItem('token');
      setLoading(true);
      await axios.post('/admin/verification-tasks/autocheck', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchPendingTasks(currentPage, taskType); // Refresh the tasks after autocheck
    } catch (error) {
      console.error('Error performing auto-check:', error);
      setError('Error performing auto-check');
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(totalTasks / tasksPerPage);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h4" gutterBottom>
            Pending Tasks
          </Typography>
          <Box>
            <Select
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
              displayEmpty
              sx={{ marginRight: 2 }}
            >
              <MenuItem value="all">All Tasks</MenuItem>
              <MenuItem value="join_x">Join X Tasks</MenuItem>
              <MenuItem value="retweet">Retweet Tasks</MenuItem>
            </Select>
            <Button variant="contained" color="primary" onClick={handleAutoCheck}>
              AutoCheck
            </Button>
          </Box>
        </Box>
        {pendingTasks.length === 0 ? (
          <Typography variant="body1">No pending tasks</Typography>
        ) : (
          pendingTasks.map(task => (
            <Paper key={task._id} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="body1">User: {task.userId.username} {task.userId.firstName} {task.userId.lastName}</Typography>
              <Typography variant="body1">Task Name: {task.taskId.name}</Typography>
              <Typography variant="body1">Task Type: {task.taskId.type}</Typography>
              <Typography variant="body1">Reward: {task.taskId.reward}</Typography>
              <Typography variant="body1">Rejected Times: {task.rejectionCount}</Typography>
              <Typography variant="body1">Moderator message: {task.moderatorMessage}</Typography>
              <Typography variant="body1">Link: <a href={decodeURIComponent('https://x.com/' + task.link)} target="_blank" rel="noopener noreferrer">{decodeURIComponent(task.link)}</a></Typography>
              <Typography variant="body1">Completed At: {new Date(task.completedAt).toLocaleString()}</Typography>
              <Select
                value={moderatorMessages[task._id] || ''}
                onChange={(e) => handleModeratorMessageChange(task._id, e.target.value)}
                displayEmpty
                sx={{ mt: 2, width: '100%' }}
              >
                <MenuItem value="" disabled>Select Reason</MenuItem>
                <MenuItem value="userNotFoundOnX">User Not Found on X</MenuItem>
                <MenuItem value="retweetNotFoundOnYourXPage">Retweet Not Found on Your X Page</MenuItem>
                <MenuItem value="youNotSubscribedToOurXPage">You Not Subscribed to Our X Page</MenuItem>
              </Select>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={() => handleApprove(task._id)}>
                  Approve
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleDisapprove(task._id)}>
                  Disapprove
                </Button>
              </Box>
            </Paper>
          ))
        )}
        {totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Button
                key={index}
                variant="contained"
                color={currentPage === index + 1 ? 'primary' : 'default'}
                onClick={() => setCurrentPage(index + 1)}
                sx={{ margin: '0 5px' }}
              >
                {index + 1}
              </Button>
            ))}
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default VerificationTasks;
