import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Box, Paper, Link } from '@mui/material';

const Deposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/admin/deposits', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setDeposits(response.data);
        setLoading(false);
      } catch (error) {
        setError('Error fetching deposits');
        setLoading(false);
      }
    };

    fetchDeposits();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Deposits
          </Typography>
          {deposits.map(deposit => (
            <Paper key={deposit._id} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="body1">User: {deposit.userId.firstName} {deposit.userId.lastName}</Typography>
              <Typography variant="body1">Amount: {deposit.amount}</Typography>
              <Typography variant="body1">
                Transaction ID: 
                <Link href={`https://bscscan.com/tx/${deposit.txId}`} target="_blank" rel="noopener noreferrer">
                  {deposit.txId}
                </Link>
              </Typography>
              <Typography variant="body1">Date: {new Date(deposit.date).toLocaleString()}</Typography>
            </Paper>
          ))}
        </Box>
      </Paper>
    </Container>
  );
};

export default Deposits;
