import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Box, Paper, Button, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

const Withdrawals = () => {
  const [withdrawals, setWithdrawals] = useState({ pendingWithdrawals: [], allWithdrawals: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [publicKey, setPublicKey] = useState('');

  useEffect(() => {
    const fetchWithdrawals = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found, please log in again.');
          setLoading(false);
          return;
        }

        const response = await axios.get('/admin/withdrawals', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setWithdrawals(response.data);
        setPublicKey(response.data.publicKey);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Token is invalid, please log in again.');
        } else {
          setError('Error fetching withdrawals');
        }
        setLoading(false);
      }
    };

    fetchWithdrawals();
  }, []);

  const handleConfirm = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/admin/withdrawals/confirm/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const response = await axios.get('/admin/withdrawals', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setWithdrawals(response.data);
    } catch (error) {
      setError('Error confirming withdrawal');
    }
  };

  const handleCancel = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`/admin/withdrawals/cancel/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const response = await axios.get('/admin/withdrawals', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setWithdrawals(response.data);
    } catch (error) {
      setError('Error canceling withdrawal');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 3, margin: 3 }}>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Withdrawals
          </Typography>
          <Typography variant="body1">
            Public Key: {publicKey}
          </Typography>

          <Typography variant="h5" gutterBottom>
            Pending Withdrawals
          </Typography>
          {withdrawals.pendingWithdrawals.map(withdrawal => (
            <Paper key={withdrawal._id} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="body1">Telegram ID: {withdrawal.userId.telegramId}</Typography>
              <Typography variant="body1">Name: {withdrawal.userId.firstName} {withdrawal.userId.lastName}</Typography>
              <Typography variant="body1">Username: {withdrawal.userId.username}</Typography>
              <Typography variant="body1">Amount: {withdrawal.amount}</Typography>
              <Typography variant="body1">
                BSC Address: 
                <MuiLink href={`https://bscscan.com/address/${withdrawal.bscAddress}`} target="_blank" rel="noopener noreferrer">
                  {withdrawal.bscAddress}
                </MuiLink>
              </Typography>
              <Typography variant="body1">
                User Profile: 
                <Link to={`/user/${withdrawal.userId._id}`}>
                  View Profile
                </Link>
              </Typography>
              <Button variant="contained" color="primary" onClick={() => handleConfirm(withdrawal._id)}>
                Confirm
              </Button>
              <Button variant="contained" color="secondary" onClick={() => handleCancel(withdrawal._id)}>
                Cancel
              </Button>
            </Paper>
          ))}

          <Typography variant="h5" gutterBottom>
            All Withdrawals
          </Typography>
          {withdrawals.allWithdrawals.map(withdrawal => (
            <Paper key={withdrawal._id} sx={{ padding: 2, marginBottom: 2 }}>
              <Typography variant="body1">Telegram ID: {withdrawal.userId.telegramId}</Typography>
              <Typography variant="body1">Name: {withdrawal.userId.firstName} {withdrawal.userId.lastName}</Typography>
              <Typography variant="body1">Username: {withdrawal.userId.username}</Typography>
              <Typography variant="body1">Amount: {withdrawal.amount}</Typography>
              <Typography variant="body1">
                BSC Address:  
                <MuiLink href={`https://bscscan.com/address/${withdrawal.bscAddress}`} target="_blank" rel="noopener noreferrer">
                  {withdrawal.bscAddress}
                </MuiLink>
              </Typography>
              <Typography variant="body1">Status: {withdrawal.status}</Typography>
              {withdrawal.status === 'completed' && (
                <Typography variant="body1">
                  Transaction Hash:  
                  <MuiLink href={`https://bscscan.com/tx/${withdrawal.txHash}`} target="_blank" rel="noopener noreferrer">
                    {withdrawal.txHash}
                  </MuiLink>
                </Typography>
              )}
              <Typography variant="body1">
                User Profile: 
                <Link to={`/user/${withdrawal.userId._id}`}>
                  View Profile
                </Link>
              </Typography>
            </Paper>
          ))}
        </Box>
      </Paper>
    </Container>
  );
};

export default Withdrawals;
