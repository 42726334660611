import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const TwitterData = () => {
  const [followers, setFollowers] = useState([]);
  const [retweets, setRetweets] = useState([]);
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [loadingRetweets, setLoadingRetweets] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchFollowers = async (query = '') => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/admin/twitter/followers?search=${query}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFollowers(response.data);
      setLoadingFollowers(false);
    } catch (error) {
      console.error('Error fetching followers:', error);
      setError('Error fetching followers');
      setLoadingFollowers(false);
    }
  };

  const fetchRetweets = async (query = '') => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/admin/twitter/retweets?search=${query}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRetweets(response.data);
      setLoadingRetweets(false);
    } catch (error) {
      console.error('Error fetching retweets:', error);
      setError('Error fetching retweets');
      setLoadingRetweets(false);
    }
  };

  useEffect(() => {
    fetchFollowers();
    fetchRetweets();
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    fetchFollowers(query);
    fetchRetweets(query);
  };

  if (loadingFollowers || loadingRetweets) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Twitter Followers and Retweets
      </Typography>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: 3 }}
      />

      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h6" gutterBottom>
          Followers
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {followers.map((follower, index) => (
                <TableRow key={index}>
                  <TableCell>{follower.username}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper elevation={3} sx={{ padding: 3 }}>
        <Typography variant="h6" gutterBottom>
          Retweets
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Tweet ID</TableCell>
                <TableCell>Retweeted At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {retweets.map((retweet, index) => (
                <TableRow key={index}>
                  <TableCell>{retweet.username}</TableCell>
                  <TableCell>{retweet.tweetId}</TableCell>
                  <TableCell>{new Date(retweet.retweetedAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default TwitterData;
